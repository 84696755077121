<template>
  <v-app>
    <v-main style="background-color: slategray;" class="fill-height">
      <v-snackbar 
        class="d-flex"
        :multi-line="true"
        timeout="2500"
        height="auto"
        middle
        bottom
        elevation="60"
        :color="toastPrompt.color"
        v-model="toastPrompt.isActive"
      >
        <span 
          class="d-block flex-grow-1"
          style="font-size: 16px; word-wrap: break-word;"
        >
          {{ toastPrompt.title }} 
        </span>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="toastPrompt.isActive = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <router-view />
    </v-main>
  </v-app>
</template>

<script>

import api from '@/services/api'; 
import { initializeMsal } from '@/services/authConfig';

export default {
  name: 'App',
  data: () => ({
    toastPrompt: {
      isActive: false,
      title: '',
      icon: '',
      color: '',
    }
  }),
  methods: {
    showToast(title, color, icon) {
      this.toastPrompt.title = title;
      this.toastPrompt.color = color;
      this.toastPrompt.icon = icon;
      this.toastPrompt.isActive = true;
    },
    async checkAuthentication() {
      await initializeMsal();
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await api.get('/authentication/ValidateViaAzure', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            console.log('validated successfully!')
            this.isLoading = false;
          } else {
            console.log('response not valid... re-routing');
            this.$router.push('/');
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.$router.push('/not-authorized'); // 401 Unauthorized, redirect to splash
          } else {
            console.error('Error during token validation:', error);
            this.isLoading = false; // Network error, stop loading but don't redirect
          }
        }
      } else {
        console.log('no token');
        if (this.$router.currentRoute.path !== '/') {
          this.$router.push('/');
        }
      }
    }
  },
  
  mounted() {
    this.checkAuthentication();
  }
};
</script>
