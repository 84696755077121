<template>
  <v-container style="background-color: slategray ;" class="center" >
    <v-snackbar 
      class="mb-2"
      :timeout="this.toastPrompt.timeoutTime"
      middle
      height="65" 
      bottom
      elevation="60"
      :color="this.toastPrompt.color" 
      v-model="toastPrompt.isActive" 
    >
      <v-icon class="mr-3">{{this.toastPrompt.icon}}</v-icon>
      <span style="font-size: 16px; vertical-align: middle;">{{ this.toastPrompt.title }}</span>
    </v-snackbar>

    <v-row class="fill-height">
      <v-col align-self="center" class="flex-column d-flex">
        <v-card class="mt-0 relative">
          <v-btn @click="redirectToLogin()" small absolute top fab right style="margin-right: -13px; margin-top: 22px;" color="transparent" elevation="0"><v-icon>mdi-exit-to-app</v-icon></v-btn>
        
          <v-expand-transition>
            <v-row class="ma-0 pa-0" v-show="initialLoadAnimations.headerAnimation">
              <v-col cols="auto" style="width: 85px;">
                <v-img class="ma-2 mr-0 justify-center" height="70" width="75" src="../assets/application/gpg-logo.png"></v-img>
              </v-col>
              <v-col cols="*">
                <v-card-title style="font-size:18px" class="pl-0">Audio PA System</v-card-title>
                <v-card-subtitle style="font-size:13px" class="pl-0">Global Precision Group</v-card-subtitle>
              </v-col>
            </v-row> 
          </v-expand-transition>
        </v-card> 

        <v-card v-if="sectionVisibility.showTransmitLiveCard" class="mt-3" elevation="5">  
            <v-subheader class="pr-1">
              <span>Play Live Recording</span>
              <v-spacer></v-spacer>
              <v-btn @click="refreshAudioData()" fab small color="transparent" elevation="0"><v-icon>mdi-restart</v-icon></v-btn>
              <v-btn @click="infoBtnClick('live-recording')" fab small color="transparent" elevation="0"><v-icon>mdi-information-outline</v-icon></v-btn>
            </v-subheader>
            <v-expand-transition>
              <v-row v-show="initialLoadAnimations.transmitLiveCardAnimation" class="justify-center ma-3 mt-3">
                <v-flex xs4 sm3>
                  <v-btn height="44" @click="recordBtnClick" class="mb-6" :color="liveRecordBtns.recordBtn.color" :disabled="liveRecordBtns.recordBtn.disabled" block>
                    <v-icon class="ml-1" :color="liveRecordBtns.recordBtn.foreground">
                      {{ liveRecordBtns.recordBtn.icon }}
                    </v-icon>
                  </v-btn>
                </v-flex>

                <v-flex xs4 sm3 class="px-2">
                  <v-btn height="44" :loading="playbackBtnLoading" @click="playBtnClick" color="primary" :disabled="liveRecordBtns.playBtn.disabled" block>
                    <v-icon class="ml-1" >
                      {{ liveRecordBtns.playBtn.icon }}
                    </v-icon>
                  </v-btn>
                </v-flex>

                <v-flex xs4 sm3>
                  <v-btn height="44" @click="transmitBtnClick" color="primary" :disabled="liveRecordBtns.transmitBtn.disabled" block>
                    <v-icon class="ml-1" >
                      {{ liveRecordBtns.transmitBtn.icon }}
                    </v-icon>
                  </v-btn>
                </v-flex>

                  <!-- Audio Player -->
                  <audio ref="liveRecordingAudioPlayer" @play="handlePlay" @ended="handleEnded"></audio>

              </v-row>
            </v-expand-transition>
        </v-card>

        <v-card class="mt-3 flex-grow-1" v-if="sectionVisibility.showAudioPlaylistCard">
          <v-expand-transition>
            <v-list class="pr-1" flat subheader single-line v-show="initialLoadAnimations.audioPlaylistCardAnimation">
              <v-subheader class="pr-1">
                <span>Audio Playlist</span>
                <v-spacer></v-spacer>
                <v-btn @click="infoBtnClick('audio-playlist')" fab small color="transparent" elevation="0"><v-icon>mdi-information-outline</v-icon></v-btn>
              </v-subheader>
              
              <v-progress-linear v-if="isStaticAudioPlaylistLoading" color="primary" reverse indeterminate></v-progress-linear>

              <v-list-item-group
                color="primary"
                style="max-height: 248px; overflow-y: auto;"
              >
                <v-list-item v-for="(item, i) in staticItems" :key="i" @click="audioPlaylistItemClick(item)">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-expand-transition>
        </v-card>

        <v-card class="mt-3 flex-grow-1" v-if="sectionVisibility.showSubscribedEventsCard">
          <v-expand-transition v-if="initialLoadAnimations.subscribedEventsCardAnimation">
            <v-list class="pr-1" flat subheader single-line v-show="initialLoadAnimations.subscribedEventsCardAnimation">
              <v-subheader class="pr-1">
                <span>Subscribed Audio Events</span>
                <v-spacer></v-spacer>
                <v-btn @click="toggleListEditable" fab small color="transparent" elevation="0"><v-icon>{{ isListEditable ? 'mdi-lock-open-outline' : 'mdi-lock-outline' }}</v-icon></v-btn>
                <v-btn @click="saveBtnClick()" fab small color="transparent" elevation="0"><v-icon>mdi-content-save-outline</v-icon></v-btn>
                <v-btn @click="infoBtnClick('subscribed-list')" fab small color="transparent" elevation="0"><v-icon>mdi-information-outline</v-icon></v-btn>
              </v-subheader>

              <v-progress-linear v-if="isSubscribedAudioLoading" color="primary" indeterminate></v-progress-linear>

              <v-list-item-group
                multiple
                active-class=""
                style="max-height: 250px; overflow-y: auto;"
              >
                <v-list-item v-for="(item, i) in subscribedItems" :key="i" @click="handleSubscribedListItemClick(item)">
                  <v-checkbox v-model="item.is_Active" :disabled="!isListEditable" @change="handleChange(item, i)"></v-checkbox>
                  <span>{{ item.name }}</span>
                  <v-spacer></v-spacer>
                  <v-btn @click="handleSubscribedListItemClick(item)" fab small color="transparent" elevation="0"><v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-expand-transition>
        </v-card>

        <v-card class="mt-3" v-if="sectionVisibility.showAddNewAudioCard">
          <v-expand-transition>
          <v-row v-show="initialLoadAnimations.addNewAudioCardAnimation" class="align-center pt-1 ma-3">
            <v-file-input align="center"
              max-width="100"
              chips
              accept="audio/*"
              label="Add New Audio"
              v-model="fileInputValue"
            ></v-file-input>

            <v-btn color="primary" width="60" height="50" :disabled="isButtonDisabled" @click="addFileDialog"  class="ml-3">
              <v-icon
                dark
                center
              >
                mdi-upload
              </v-icon>
            </v-btn>
          </v-row>
        </v-expand-transition>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog
        v-model="confirmDialog.isActive"
        max-width="600"
        width="auto"
      >
        <template>
          <v-card>
            <v-toolbar
              color="primary"
              title="Opening from the bottom"
            >
              <v-toolbar-title style="color: white;" class="text-white">
                  <span>{{ confirmDialog.dialogTitle }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="openDeleteTransmissionDialog(confirmDialog.transmissionId)" fab small color="transparent" elevation="0"><v-icon medium color="white">mdi-delete-forever</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="d-flex align-center justify-center">
              <audio class="my-audio-player mt-5" controls style="width: 80vw; max-width: 300px;">
                  <source :src="this.confirmAudioURL" type="audio/wav">
                  Your browser does not support the audio element.
              </audio>
          </v-card-text>
            <v-card-actions class="justify-center"> 
              <v-btn
                variant="text"
                @click="confirmDialog.isActive = false"
              >Cancel</v-btn>

              <v-btn
                variant="text"
                color="primary"
                @click="transmitStaticAudio"
              >Transmit Audio</v-btn> 
            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>

    <v-dialog
        v-model="infoDialog.isActive"
        max-width="600"
        width="auto"
      >
        <template>
          <v-card>
            <v-toolbar
              color="primary"
              title="Opening from the bottom"
            >
            <v-toolbar-title style="color: white;" class="text-white">{{ infoDialog.dialogTitle }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-5">
              
              {{ infoDialog.dialogTextBody }}

            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn 
                variant="text"
                color="transparent" elevation="0"
                @click="infoDialog.isActive = false"
              >Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>

    <v-dialog
        v-model="eventInfoDialog.isActive"
        max-width="600"
        width="*"
      >
        <template>
          <v-card>
            <v-toolbar
              color="primary"
              title="Opening from the bottom"
            >
              <v-toolbar-title style="color: white;" class="text-white" >
                <span> Event: {{ eventInfoDialog.name }}</span>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn @click="openDeleteTransmissionDialog(eventInfoDialog.transmissionId)" fab small color="transparent" elevation="0"><v-icon medium color="white">mdi-delete-forever</v-icon></v-btn>

            </v-toolbar>
            <v-card-text class="mt-5">  
              <!-- Format and display time -->
              <div style="font-size: 16px;">
                <b>Scheduled Time:</b> {{ formatTime(eventInfoDialog.scheduled_Time)  }}
              </div>

              <!-- Display description -->
              <div class="my-3" style="font-size: 16px;">
               <b>Description:</b> {{ eventInfoDialog.description }}
              </div>

              <!-- Frequency -->
              <div class="mt-3 mb-0" style="font-size: 16px;">
                <b>Frequency:</b> Daily
              </div>

            </v-card-text>
            <v-card-actions class="mt-0 pt-0 justify-end">

              <v-btn
                variant="text"
                color="transparent" elevation="0"
                @click="eventInfoDialog.isActive = false"
              >Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>

    <v-dialog
        v-model="addAudioDialog.isActive"
        max-width="600"
        width="*"
        min-height="*"
      >
        <template>
          <v-card>
            <v-toolbar
              color="primary"
              title="Opening from the bottom"
            >
            <v-toolbar-title style="color: white;" class="text-white">{{ addAudioDialog.dialogTitle }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-4">  
             
              <v-form ref="form">
                <v-text-field
                  v-model="form.name"
                  label="Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="form.description"
                  label="Description"
                  :rules="descriptionRules"
                  required 
                ></v-text-field>

                <v-combobox
                  label="Audio Icon (Optional)"
                  :items="audioIcons"
                  v-model="form.icon"
                >
                  <template slot="item" slot-scope="data">
                    <v-icon>{{data.item}}</v-icon>
                    <span class="ml-5"> {{data.item}} </span> 
                  </template> 
                </v-combobox>

                <!-- Additional options for non-static -->
                <template v-if="form.isScheduled">
                  <v-text-field
                    v-model="form.scheduledTime"
                    label="Scheduled Time"
                    type="time"
                    :rules="scheduledTimeRules"
                    required
                  ></v-text-field>
                  <v-switch v-model="form.isActive" label="Set Active"></v-switch>
                </template>

                <v-switch v-model="form.isScheduled" label="Scheduled"></v-switch>

              </v-form>
            </v-card-text>
            <v-card-actions class="mt-0 pb-4 pt-0 justify-end">            
              <v-btn
                variant="text" large
                color="transparent" elevation="0"
                @click="addAudioDialog.isActive = false"
              >Close</v-btn>

              <v-btn
                :loading="btnLoading"
                class="px-3"
                variant="text"
                color="primary" large elevation="0"
                @click="submitForm"
              >Add Audio</v-btn>

            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>

    <v-dialog
        v-model="confirmDeleteDialog.isActive"
        max-width="600"
        width="*"
        min-height="*"
      >
        <template>
          <v-card>
            <v-toolbar
              color="primary"
              title="Opening from the bottom"
            >
            <v-toolbar-title style="color: white;" class="text-white">{{ confirmDeleteDialog.dialogTitle }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-4">Warning, this action cannot be reversed.</v-card-text>

            <v-card-actions class="mt-0 pb-4 pt-0 justify-end">            
              <v-btn
                color="normal"
                elevation="0" size="x-large"
                @click="confirmDeleteDialog.isActive = false"
              >Cancel</v-btn>

              <v-btn
                :loading="btnLoading"
                elevation="0"
                color="error"
                size="x-large"
                @click="deleteAudioTransmission(confirmDeleteDialog.transmissionId)"
              >
              Delete Audio
              </v-btn>


            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>

    <v-dialog v-model="tokenInvalidDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline font">Your Session Expired...</v-card-title>
        <v-card-text>Please sign in to continue using the app.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="px-6" @click="redirectToLogin">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="tooManyRequestsDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline font">Too Many Server Requests</v-card-title>
        <v-card-text>Wait a minute before trying again, or log back in...</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="px-6" @click="redirectToLogin">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>

import api from '@/services/api'
import { EventBus } from '../event-bus'; 
import RecordRTC from 'recordrtc';

export default {
  name: 'AudioTransmitter',
  props: {
    viewType: {
      type: String,
      default: "Audio Transmitter Admin",
      required: false
    }
  },
  data: () => ({

    recorder: null,
    audioStream: null,
    audioChunks: [], 
    isRecording: false,
    liveAudioURL: "",

    isSubscribedAudioLoading: false,
    isStaticAudioPlaylistLoading: false,

    //ANIMATIONS
    loader: null,
    playbackBtnLoading: false,
    initialLoadAnimations: {
      headerAnimation: false,
      transmitLiveCardAnimation: false,
      subscribedEventsCardAnimation: false,
      audioPlaylistCardAnimation: false,
      addNewAudioCardAnimation: false,
    },
    sectionVisibility: {
      showTransmitLiveCard: false,
      showAudioPlaylistCard: false,
      showSubscribedEventsCard: false,
      showAddNewAudioCard: false,
    },
    //BUTTONS
    btnLoading: false,
    //TOAST INFORMATION
    toastSuccessColor: 'green darken-1',
    toastFailColor: 'red darken-1',
    toastInfoColor: 'orange darken-1',
    toastPrompt: {
      isActive: false,
      title: '',
      icon: '',
      color: '',
      timeoutTime: 3000,
    },
    isListEditable: false,
    //FORM DATA AND RULES
    form: { 
      name: '',
      description: '',
      isScheduled: false,
      scheduledTime: '',
      isActive: false,
      icon: '',
    },
    nameRules: [v => !!v || 'Name is required'],
    descriptionRules: [v => !!v || 'Description is required'],
    scheduledTimeRules: [
      v => {
        if (!v) {
          return 'Scheduled Time is required when Scheduled is selected';
        }
        if (!/^\d{2}:\d{2}(:\d{2})?$/.test(v)) {
          return 'Scheduled Time format is invalid';
        }
        return true;
      },
    ],
    //DIALOG OBJECTS
    tokenInvalidDialog: false,
    tooManyRequestsDialog: false,
    addAudioDialog: {
      isActive: false,
      dialogTitle: "Add New Audio",
    },
    confirmDialog: {
      transmissionId: null,
      isActive: false,
      dialogTitle: "Confirm Audio Transmission",
    },
    confirmDeleteDialog: {
      transmissionId: null,
      isActive: false,
      dialogTitle: "Delete This Audio?",
    },
    eventInfoDialog: {
      transmissionId: null,
      isActive: false,
      dialogTitle: "Audio Event Details",
      scheduled_Time: null,
      name: "",
      description: "",
      frequency: "Daily",
    },
    infoDialog: {
      isActive: false,
      dialogTitle: "Dialog Info",
      dialogTextBody: "",
    },
    //AUDIO PLAYER
    audioSrc: null,
    confirmAudioURL: null,
    //Live Record Buttons
    liveRecordBtns: {
      recordBtn: {
        icon: "mdi-microphone",
        disabled: false,
        color: "primary",
        foreground: "white"
      },
      playBtn:{
        icon: "mdi-play",
        disabled: true,
        isPlaying: false,
        color: "primary",
      },
      transmitBtn: {
        icon: "mdi-speaker-wireless", 
        disabled: true,
        color: "primary",
      }
    },
    //File Information
    fileInputValue: null,
    fileInputBtn: {
      disabled: true,
      text: "Input File"
    },
    //data arrays
    audioIcons: [],
    staticItems: [],
    changedItems: [],  
    subscribedItems: [],
  }),
  computed: {
    isButtonDisabled() {
      return !this.fileInputValue;
    },
  },
  watch: {
    'confirmDialog.isActive': function(newValue) {
      if (!newValue) {
        this.confirmAudioURL = null;
      }
    },
  },
  mounted() {
    try {
      this.getStaticAudioFiles()
      this.getSubscribedAudioFiles();
      this.getAudioIcons();
    } catch {
      console.log("backend connection failure")
    }

    this.showAnimations();
    this.setAppView(this.viewType);
  },
  created() {
    EventBus.$on('token-not-valid', this.handleTokenNotValid);
    EventBus.$on('too-many-requests', this.handleTooManyRequests);
  },
  beforeDestroy() {
    //prevent memory leak
    EventBus.$off('token-not-valid', this.handleTokenNotValid);
    EventBus.$on('too-many-requests', this.handleTooManyRequests);
  },
  methods: {
    //UI MEHTODS
    setAppView(type) {
      if(type === '') {
        this.redirectToLogin()
      }

      if(type === "Audio Transmitter Admin") {
        this.sectionVisibility.showTransmitLiveCard = true
        this.sectionVisibility.showAudioPlaylistCard = true
        this.sectionVisibility.showSubscribedEventsCard = true
        this.sectionVisibility.showAddNewAudioCard = true
      } else if(type === "Audio Transmitter User") {
        this.sectionVisibility.showTransmitLiveCard = true
        this.sectionVisibility.showAudioPlaylistCard = true
      } else if(type === "Audio Transmitter Kiosk") {
        this.sectionVisibility.showAudioPlaylistCard = true
      } else {
        this.sectionVisibility.showTransmitLiveCard = true
        this.sectionVisibility.showAudioPlaylistCard = true
      }
    },
    showToast(title, color, icon, timeout = 3000) {
      this.toastPrompt.title = title
      this.toastPrompt.color = color
      this.toastPrompt.icon = icon
      this.toastPrompt.timeoutTime = timeout
      this.toastPrompt.isActive = true
    },
    hideToast() {
      this.toastPrompt.isActive = false
    },
    showAnimations() {
      this.initialLoadAnimations.headerAnimation = true
      this.initialLoadAnimations.transmitLiveCardAnimation = true
      this.initialLoadAnimations.audioPlaylistCardAnimation = true
      this.initialLoadAnimations.subscribedEventsCardAnimation = true
      this.initialLoadAnimations.addNewAudioCardAnimation = true
    },
    handleTokenNotValid() {
      this.tokenInvalidDialog = true;
    },
    handleTooManyRequests() {
      this.tooManyRequestsDialog = true;
    },
    redirectToLogin() {
      localStorage.removeItem('token');
      window.location.href = '/'; 
    },
    //START POPULATE LISTS
    async getSubscribedAudioFiles() {
      try {
        this.isSubscribedAudioLoading = true;

        const response = await api.get('/Audio/GetSubscribedAudioFiles/');
        await new Promise(resolve => setTimeout(resolve, 1000));

        if (response.status === 200) {
          this.subscribedItems = response.data;
        } else if (response.status === 401) {
          this.tokenInvalidDialog = true;
        } else if (response.status === 429) {
          this.tooManyRequestsDialog = true;
        } else {
          this.showToast("An unexpected error occurred.", "red", "mdi-alert-circle");
        }
      } catch (error) {
        if (error.response) {
          this.showToast(`Error ${error.response.status}: ${error.response.statusText}`, "red", "mdi-alert-circle");
        } else if (error.request) {
          this.showToast("No response from the server. Please check your connection.", "dark", "mdi-wifi-off");
        } else {
          this.showToast("An error occurred while processing your request.", "red", "mdi-alert-octagram");
        }
      }

      this.isSubscribedAudioLoading = false;
    },
    async getStaticAudioFiles() {
      try {
        this.isStaticAudioPlaylistLoading = true;

        const response = await api.get('/Audio/GetStaticAudioFiles/');
        await new Promise(resolve => setTimeout(resolve, 1000));

        if (response.status === 200) {
          this.staticItems = response.data;
        } else if (response.status === 401) {
          this.tokenInvalidDialog = true;
        } else if (response.status === 429) {
          this.tooManyRequestsDialog = true;
        } else {
          this.showToast("An unexpected error occurred.", "red", "mdi-alert-circle");
        } 
      } catch (error) {
        if (error.response) {
          this.showToast(`Error ${error.response.status}: ${error.response.statusText}`, "red", "mdi-alert-circle");
        } else if (error.request) {
          this.showToast("No response from the server. Please check your connection.", "dark", "mdi-wifi-off");
        } else {
          this.showToast("An error occurred while processing your request.", "red", "mdi-alert-octagram");
        }
      }

      this.isStaticAudioPlaylistLoading = false;

    },
    async getAudioIcons() {
      try {
        const response = await api.get('/Audio/GetAudioIcons/');

        if (response.status === 200) {
          this.audioIcons = response.data;
        } else {
          this.showToast("An unexpected error occurred.", "red", "mdi-alert-circle");
        }
      } catch (error) {
        if (error.response) {
          this.showToast(`Error ${error.response.status}: ${error.response.statusText}`, "red", "mdi-alert-circle");
        } else if (error.request) {
          this.showToast("No response from the server. Please check your connection.", "dark", "mdi-wifi-off");
        } else {
          this.showToast("An error occurred while processing your request.", "red", "mdi-alert-octagram");
        }
      }
    },
    async refreshAudioData() {
      await this.getStaticAudioFiles();
      await this.getSubscribedAudioFiles();
    },
    //START ADD AUDIO FORM 
    async submitForm() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('description', this.form.description);
        formData.append('isScheduled', this.form.isScheduled);
        formData.append('scheduledTime', this.form.scheduledTime);
        formData.append('isActive', this.form.isActive);
        formData.append('audioFile', this.fileInputValue);
        formData.append('icon', this.form.icon);

        this.btnLoading = true

        // Make the POST request with the FormData
        await api.post('/Audio/AddAudioTransmission/', formData)
          .then(response => {
            console.log(response.data);

            this.addAudioDialog.isActive = false
            this.showToast("Success! Audio Added.", this.toastSuccessColor, "mdi-check-circle")

            this.resetFormValues();
            this.refreshAudioData();
            this.fileInputValue = null
          })
          .catch(error => {
            console.error(error);
            this.showToast(error.response.data, this.toastFailColor, "mdi-alert")
          });

        this.btnLoading = false

      }
      
    },
    resetFormValues() {
      this.form.name = "";
      this.form.description = "";
      this.form.scheduledTime = null;
      this.form.isScheduled = false;
      this.form.isActive = false;
      this.form.icon = '';
    },
    addFileDialog() {
      this.addAudioDialog.isActive = true
    },
    //START SUBSCRIBED EVENTS LISTS
    saveBtnClick() {

      const hasChanges = this.changedItems.length > 0

      if (hasChanges) {
        const intArr = []

        this.changedItems.forEach((index) => {
          const item = this.subscribedItems[index];
          intArr.push(item.audio_Transmission_ID);
        });

        api.post(`/Audio/UpdateSubscribedStates/ `, intArr)
        .then(response => {

          this.showToast("Events have been updated successfully!", this.toastSuccessColor, "mdi-checkbox-marked-circle")
          this.refreshAudioData();
          this.isListEditable = false
          console.log(response.data);

        })
        .catch(error => {
          console.error(error);
          this.showToast(error.response.data, this.toastFailColor, "mdi-alert");
        });
      } else {
        this.showToast("No changes were made..", this.toastInfoColor, "mdi-alert-box");
      }

      this.changedItems = [];

    },
    handleChange(item, index) {
      if (!this.changedItems.includes(index)) {
        this.changedItems.push(index);
      }
    },
    infoBtnClick(type) {
      if(type === "audio-playlist") {
        this.infoDialog.isActive = true;
        this.infoDialog.dialogTitle = "Audio Playlist Help";
        this.infoDialog.dialogTextBody = "The audio playlist consists of static .wav audio files. You can select any item from the list, and the chosen audio will be played through the speaker system. Unlike the subscribed playlist, these actions are performed on demand and are not scheduled at regular intervals.";
      } else if(type === "subscribed-list") {
        this.infoDialog.isActive = true;
        this.infoDialog.dialogTitle = "Subscribed Audio Events Help";
        this.infoDialog.dialogTextBody = "Subscribed audio events are recurring daily events that you can toggle on or off. When enabled, these events will automatically play at their scheduled daily times. If you choose to disable them, they will not play as scheduled.";
      } else if(type === "live-recording") {
        this.infoDialog.isActive = true;
        this.infoDialog.dialogTitle = "Play Live Audio Help";
        this.infoDialog.dialogTextBody = "The 'Play Live Audio' feature allows you to send your local recordings to the speaker system. It's simple to use:\n\n1. Click the 'Record' button (it will turn red to show recording is in progress).\n2. Click the same button again to stop recording.\n3. After recording, you can review it by clicking the 'Play' button.\n4. To transmit the recorded audio, click the third button with the upload symbol. The speaker will now play your recorded message.";
      }
    },
    toggleListEditable() {
      this.isListEditable = !this.isListEditable;
    },
    formatTime(datetime) {
      const date = new Date(datetime);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        return "Invalid datetime";
      }

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

      return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
    },
    handleSubscribedListItemClick(item)
    {
      console.log(item)
      this.eventInfoDialog.transmissionId = item.audio_Transmission_ID
      this.eventInfoDialog.dialogTitle = "Event Details"
      this.eventInfoDialog.scheduled_Time = item.scheduled_Time
      this.eventInfoDialog.name = item.name
      this.eventInfoDialog.description = item.description
      this.eventInfoDialog.frequency = "Daily"

      this.eventInfoDialog.isActive = true;
  
    },
    //START STATIC AUDIO METHODS
    updateAudioPlayerURL(byteArr) {
      this.toastPrompt.isActive = false;
      this.confirmAudioURL = null; 
      try {
        const byteArray = Uint8Array.from(atob(byteArr).split("").map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: "audio/wav" });
        this.confirmAudioURL = URL.createObjectURL(blob);
      } catch(error) {
        this.confirmAudioURL = null;
      }
    },
    async audioPlaylistItemClick(item) {
      this.confirmAudioURL = null;
      console.log("audio playlist clicked: " + item.name)
      if (item) {
        this.updateAudioPlayerURL(item.audio_Bytes);

        this.confirmDialog.isActive = true;
        this.confirmDialog.transmissionId = item.audio_Transmission_ID;
        this.confirmDialog.dialogTitle = item.name;
      } else {
        this.confirmAudioURL = null;
        //this.showToast("No audio data in payload..", this.toastFailColor, "mdi-alert");
      }
    },
    transmitStaticAudio() {
      this.confirmDialog.isActive = false
      console.log("transmitting Audio Now..")

      api.post(`/Audio/TransmitStaticAudio/` + this.confirmDialog.transmissionId)
      .then(response => {
        console.log(response.data);
        this.showToast("transmitting Audio Now..", this.toastSuccessColor, "mdi-checkbox-marked-circle");
      })
      .catch(error => {
        if (error.response && error.response.status) {
          if (error.response.status === 400) {
            this.showToast(error.response.data, this.toastFailColor, "mdi-alert");
          } else {
            this.showToast("Unexpected error occurred.", this.toastFailColor, "mdi-alert");
          }
        } else {
          this.showToast("Network error. Try refreshing or logging back in.", this.toastFailColor, "mdi-alert");
        }
      });
    },
    //LIVE RECORD METHODS
    async recordBtnClick() {
      if (!this.isRecording) {
        await this.startRecording();
        this.showToast("You Are Currently Recording.", this.toastInfoColor, "mdi-microphone-message", 100000);
        this.isRecording = true;
        this.liveRecordBtns.recordBtn.color = "red darken-2"
        this.liveRecordBtns.recordBtn.foreground = "white"
        this.liveRecordBtns.recordBtn.icon = 'mdi-stop'; // Change icon to stop
        this.liveRecordBtns.playBtn.disabled = true;
        this.liveRecordBtns.transmitBtn.disabled = true;

      } else {
        await this.stopRecording();
        this.hideToast()
        this.isRecording = false;
        this.liveRecordBtns.recordBtn.color = "primary"
        this.liveRecordBtns.recordBtn.icon = 'mdi-microphone'; // Change icon back to record
        this.liveRecordBtns.playBtn.disabled = false;
        this.liveRecordBtns.transmitBtn.disabled = false; 
      }
    },
    async transmitBtnClick() {
      if (this.liveTransmitAudioBlob) {
        const formData = new FormData();
        formData.append('audioFile', this.liveTransmitAudioBlob, 'audio.wav'); 

        try {
            const response = await api.post('/Audio/TransmitLiveAudio/', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data' 
              }
            });

            if(response.status === 200) {
              console.log(response.data);
              this.showToast("Success! Transmitting Audio.", this.toastSuccessColor, "mdi-checkbox-marked-circle");

              this.isRecording = false;
              this.liveRecordBtns.recordBtn.disabled = false
              this.liveRecordBtns.playBtn.disabled = true;
              this.liveRecordBtns.transmitBtn.disabled = true;
            } else {
              this.showToast("ESP32 Could Not Receive Transmission.", this.toastFailColor, "mdi-alert");
            }
            
            
        } catch (error) {
          this.showToast("ESP32 Could Not Receive Transmission", this.toastFailColor, "mdi-alert");
        }
      }
    },
    handlePlay() {
      this.playbackBtnLoading = true;
    },
    handleEnded() {
      this.playbackBtnLoading = false;
    },
    stopPlayAudio() {
      this.$refs.liveRecordingAudioPlayer.src = null
      this.playbackBtnLoading = false;
    },
    async playBtnClick() {
      if (this.$refs.liveRecordingAudioPlayer.src) { 
        this.$refs.liveRecordingAudioPlayer.play();
      }
    },
    async startRecording() {
      try {
        this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        this.recorder = RecordRTC(this.audioStream, {
          type: 'audio',
          mimeType: 'audio/wav',
          recorderType: RecordRTC.StereoAudioRecorder,
          numberOfAudioChannels: 1
        });
        
        this.recorder.startRecording();

        this.liveRecordBtns.recordBtn.icon = 'mdi-stop';
        this.liveRecordBtns.playBtn.disabled = true;
        this.liveRecordBtns.transmitBtn.disabled = true;

      } catch (error) {
        console.error('Error accessing media stream:', error);
      }
    },
    async stopRecording() {
      if (this.recorder) {
        this.recorder.stopRecording(() => {
          const audioBlob = this.recorder.getBlob();
          this.liveAudioURL = URL.createObjectURL(audioBlob);
          this.$refs.liveRecordingAudioPlayer.src = this.liveAudioURL;
          this.liveTransmitAudioBlob = audioBlob;
        });

        const tracks = this.audioStream.getTracks();
        tracks.forEach(track => track.stop());

        this.liveRecordBtns.recordBtn.icon = 'mdi-microphone';
        this.liveRecordBtns.playBtn.disabled = false;
        this.liveRecordBtns.transmitBtn.disabled = false;
      }
    },
    //MISC METHODS
    downloadWavFromArray(byteArray, fileName) {
      const blob = new Blob([byteArray], { type: 'audio/wav' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;  
      a.click();
      URL.revokeObjectURL(url);
    },
    openDeleteTransmissionDialog(transmissionId) {
      this.confirmDialog.isActive = false;
      this.eventInfoDialog.isActive = false;
      this.confirmDeleteDialog.isActive = true;
      this.confirmDeleteDialog.transmissionId = transmissionId
    },
    async deleteAudioTransmission(transmissionId) {
      this.btnLoading = true;
      await api.delete(`/Audio/DeleteAudioTransmission/`+ transmissionId)
      .then(response => {
        console.log(response.data);
        this.confirmDeleteDialog.isActive = false;
        this.showToast("Audio Has Been Deleted!", this.toastSuccessColor, "mdi-checkbox-marked-circle");
        this.refreshAudioData();
      })
      .catch(error => {
        console.error(error);
        this.confirmDeleteDialog.isActive = false;
        this.showToast(error.response.data, this.toastFailColor, "mdi-alert");
      });
      this.btnLoading = false;
    }
  }
}
</script>

<style scoped>
html, body {
    height: 100%;
    margin: 0;
}
.my-audio-player {
    max-width: 100%;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>