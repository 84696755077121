// ./services/userStore.js
import Vue from 'vue';

const state = Vue.observable({
  userProfile: null,
  hasAuthenticated: false,
});

const setUserProfile = (profile) => {
  state.userProfile = profile;
  state.hasAuthenticated = true;
};

const clearUserProfile = () => {
  state.userProfile = null;
  state.hasAuthenticated = false;
};

export const useUserState = () => {
  return {
    state,
    setUserProfile,
    clearUserProfile,
  };
};
