import Vue from 'vue';
import Router from 'vue-router';
import AudioTransmitter from '../components/AudioTransmitter.vue';
import Splash from '../components/Splash.vue';
import NotAuthorized from '../components/NotAuthorized.vue';

Vue.use(Router);

const routes = [
  {
    path: '/audio',
    name: 'Home',
    component: AudioTransmitter,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'Login',
    component: Splash
  },
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: NotAuthorized
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new Router({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next('/');
  } else {
    next();
  }
});

export default router;
