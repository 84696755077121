<template>
  <div style="background-color: white; height: 100vh;" class="d-flex justify-center align-center">
    <v-col v-if="!isAzureLoginLoading">
      <v-row class="justify-center align-center">
        <v-img max-width="160" src="@/assets/application/gpg-logo-cropped.png"></v-img>
      </v-row>

      <v-row class="text-h5 mt-6 justify-center align-center">
        <p>Global Precision Group</p>
      </v-row>

      <v-row class="text-subtitle-1 mt-0 justify-center align-center">
        <p>Audio Transmitter</p>
      </v-row>

      <v-row class="mt-12 justify-center align-center">
        <v-btn
          :loading="isAzureLoginLoading"
          elevation="0"
          height="50"
          outlined
          color="primary"
          @click="validateWithAzure"
        >
          <v-icon start class="pl-1 mr-2">mdi-login</v-icon>
          Login with Azure
        </v-btn>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-row class="justify-center align-center">
        <v-progress-circular indeterminate color="primary" :width="4" size="60"></v-progress-circular>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import msalInstance from '@/services/authConfig';
import api from '@/services/api';
import { useUserState } from '@/services/userStore';

export default {
  name: 'SplashView',
  data() {
    return {
      isAzureLoginLoading: false,
      userStore: useUserState()
    };
  },
  methods: {
    async validateWithAzure() {
      if (this.isAzureLoginLoading) return;

      this.isAzureLoginLoading = true;
      const token = await this.getMSALToken();
      if (token) {
        console.log('MSAL token was successfully received');

        localStorage.setItem('token', token);

        const isValid = await this.validateToken(token);
        if (isValid) {
          this.$router.push('/audio');
        } else {
          console.log('Token validation failed');
        }
      } else {
        console.log('MSAL Token was Invalid');
      }
      this.isAzureLoginLoading = false;
    },
    
    async getMSALToken() {
      const loginRequest = {
        scopes: ['api://9a62fc72-7975-4173-afc5-28805f40f4f5/access_as_user', 'User.Read'],
        prompt: 'select_account'
      };

      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
          try {
            const silentToken = await msalInstance.acquireTokenSilent(loginRequest);
            return silentToken.accessToken;
          } catch (silentError) {
            console.log('Silent token acquisition failed, falling back to interactive method.');
            if (silentError.errorCode === 'interaction_required' || silentError.errorCode === 'monitor_window_timeout') {
              const loginResponse = await msalInstance.loginPopup(loginRequest);
              return loginResponse.accessToken;
            } else {
              throw silentError;
            }
          }
        } else {
          const loginResponse = await msalInstance.loginPopup(loginRequest);
          return loginResponse.accessToken;
        }
      } catch (error) {
        console.error('Error during Azure MSAL authentication:', error);
        return null;
      }
    },

    async validateToken(accessToken) {
      try {
        const response = await api.get('/authentication/ValidateViaAzure', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error during token validation:', error);
        return false;
      }
    },

    async getUserProfile() {
      try {
        const response = await api.get('/authentication/GetUser');
        if (response.status === 200) {
          return response.data;
        } else {
          console.error('Failed to get user');
          return null;
        }
      } catch (error) {
        console.error('Error while getting user', error);
        return null;
      }
    }
  }
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}
</style>
