import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: "9a62fc72-7975-4173-afc5-28805f40f4f5",
    authority: "https://login.microsoftonline.com/5f2a68e9-3e1c-4d44-9c19-6c98650dcbbf",
    redirectUri: window.location.origin,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const initializeMsal = async () => {
  await msalInstance.initialize();
};

export default msalInstance;